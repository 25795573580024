const PlayIcon = ({ size = "32", color = "white", onClick = () => {} }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7767 32.3631C25.6133 32.3631 32.7767 25.1996 32.7767 16.3631C32.7767 7.52651 25.6133 0.363068 16.7767 0.363068C7.94018 0.363068 0.776733 7.52651 0.776733 16.3631C0.776733 25.1996 7.94018 32.3631 16.7767 32.3631ZM25.6903 15.9563C25.5581 15.6595 25.1643 15.4322 24.3767 14.9774L14.1767 9.08847C13.3891 8.63375 12.9953 8.40639 12.6722 8.44036C12.3903 8.46998 12.1343 8.61781 11.9677 8.84709C11.7767 9.10996 11.7767 9.56467 11.7767 10.4741L11.7767 22.2521C11.7767 23.1615 11.7767 23.6162 11.9677 23.8791C12.1343 24.1084 12.3903 24.2562 12.6722 24.2858C12.9953 24.3198 13.3891 24.0924 14.1767 23.6377L24.3767 17.7487L24.3768 17.7487C25.1643 17.294 25.5581 17.0666 25.6903 16.7698C25.8056 16.5109 25.8056 16.2153 25.6903 15.9563Z"
        fill={color}
      />
    </svg>
  );
};

export default PlayIcon;
