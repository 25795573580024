const PlayClearIcon = ({
  size = "32",
  color = "white",
  onClick = () => {},
  id = "clip0_78_2",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 9 12"
      // viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M9.13246 4.6144C9.92005 5.06912 10.3138 5.29648 10.446 5.59331C10.5613 5.85221 10.5613 6.14787 10.446 6.40678C10.3138 6.70361 9.92005 6.93097 9.13246 7.38568L2.68245 11.1096C1.89486 11.5643 1.50107 11.7917 1.17793 11.7577C0.89607 11.7281 0.640021 11.5802 0.473438 11.351C0.282455 11.0881 0.282455 10.6334 0.282455 9.72395L0.282455 2.27613C0.282455 1.3667 0.282455 0.911983 0.473438 0.649117C0.640022 0.419835 0.896071 0.272005 1.17793 0.242381C1.50107 0.208417 1.89486 0.435776 2.68246 0.890493L9.13246 4.6144Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={`${id}`}>
          <rect width="11" height="12" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlayClearIcon;
