const InfoCircle = ({
  id = "",
  size = "32",
  color = "black",
  backgroundColor = "white",
  onClick = () => {},
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_150_6)">
        <circle cx="12.4747" cy="12" r="12" fill={backgroundColor} />
        {/* <circle cx="12.4747" cy="12" r="12" fill="#D9D9D9" /> */}
        <path
          d="M15.2946 16.6804C15.2359 16.5964 15.1267 16.5628 15.0301 16.609C13.997 17.0962 12.8253 17.6127 12.5943 17.6589C12.5901 17.6547 12.5775 17.6463 12.5691 17.6295C12.5397 17.5875 12.5229 17.5329 12.5229 17.4615C12.5229 16.8778 12.9639 15.1014 13.8332 12.1827C14.5681 9.73013 14.6521 9.22199 14.6521 9.054C14.6521 8.79363 14.5513 8.57525 14.3623 8.41987C14.1818 8.27289 13.934 8.19729 13.619 8.19729C13.0941 8.19729 12.4893 8.39467 11.767 8.80623C11.0657 9.20099 10.2804 9.87292 9.44046 10.801C9.37326 10.8724 9.36906 10.9816 9.42366 11.0614C9.47825 11.1412 9.58324 11.1706 9.67563 11.137C9.9696 11.0152 11.4562 10.4063 11.6746 10.2719C11.851 10.1627 12.0064 10.1039 12.1324 10.1039C12.1366 10.1039 12.1408 10.1039 12.145 10.1039C12.145 10.1123 12.1492 10.1249 12.1492 10.1417C12.1492 10.2677 12.124 10.4231 12.0694 10.591C10.8053 14.6898 10.188 17.2054 10.188 18.2762C10.188 18.6542 10.293 18.9566 10.4987 19.1791C10.7087 19.4059 10.9943 19.5193 11.3429 19.5193C11.7166 19.5193 12.1702 19.3639 12.7329 19.0406C13.2747 18.7298 14.1062 18.0453 15.2694 16.9534C15.3408 16.8778 15.3534 16.7644 15.2946 16.6804ZM15.2526 4.79985C15.0469 4.58987 14.7823 4.48068 14.4673 4.48068C14.0768 4.48068 13.7324 4.63607 13.451 4.94263C13.1739 5.245 13.0353 5.61036 13.0353 6.03872C13.0353 6.37468 13.1403 6.65605 13.3418 6.87023C13.5476 7.08861 13.808 7.1978 14.1188 7.1978C14.4967 7.1978 14.8327 7.03402 15.1267 6.71065C15.4164 6.39148 15.5634 6.02192 15.5634 5.60196C15.5634 5.2786 15.4584 5.00983 15.2526 4.79985Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_150_6">
          <rect
            width="24"
            height="24"
            fill={backgroundColor}
            transform="translate(0.474731)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InfoCircle;
