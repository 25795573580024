const SpotifyIcon = ({ id = "", size = "32", color = "white" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${id === "" ? "clip0_33_51" : id})`}>
        <path
          d="M8.93135 14.8857C8.14361 14.431 7.74974 14.2036 7.61757 13.9068C7.50228 13.6479 7.50228 13.3522 7.61757 13.0933C7.74974 12.7964 8.14361 12.569 8.93135 12.1143L15.3828 8.39018C16.1704 7.93555 16.5642 7.70824 16.8873 7.74221C17.1691 7.77185 17.4252 7.91969 17.5918 8.14896C17.7827 8.41182 17.7827 8.86651 17.7827 9.77588V17.2242C17.7827 18.1335 17.7827 18.5882 17.5918 18.8511C17.4252 19.0803 17.1691 19.2282 16.8873 19.2578C16.5642 19.2918 16.1704 19.0645 15.3828 18.6099L8.93135 14.8857Z"
          fill={color}
        />
        <path
          d="M7.21727 12.1051C7.21727 11.5528 7.66499 11.1051 8.21727 11.1051H11.5844C12.1367 11.1051 12.5844 11.5528 12.5844 12.1051V14.8579C12.5844 15.4101 12.1367 15.8579 11.5844 15.8579H8.21727C7.66499 15.8579 7.21727 15.4101 7.21727 14.8579V12.1051Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5 26.7032C20.7919 26.7032 26.7032 20.7919 26.7032 13.5C26.7032 6.20807 20.7919 0.296783 13.5 0.296783C6.20807 0.296783 0.296783 6.20807 0.296783 13.5C0.296783 20.7919 6.20807 26.7032 13.5 26.7032ZM13.5 23.4691C19.0058 23.4691 23.4691 19.0058 23.4691 13.5C23.4691 7.99422 19.0058 3.53088 13.5 3.53088C7.9942 3.53088 3.53087 7.99422 3.53087 13.5C3.53087 19.0058 7.9942 23.4691 13.5 23.4691Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={id === "" ? "clip0_33_51" : id}>
          <rect width="27" height="27" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SpotifyIcon;
